import React from "react";

import CloseIcon from "../../assets/svg/CloseIcon.svg";
import FaceBook from "../../assets/svg/FaceBookIcon.svg";
import Instagram from "../../assets/svg/InstagramIcon.svg";
import TikTok from "../../assets/svg/TikTok.svg";
import Position from "../../assets/svg/PositionLIcon.svg";
import PrimaryButton from "../../components/primaryButton";
import Tag from "../../components/Tag";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface DownloadModalProps {
  onClick: (photoUrl: string, name: string) => void;
  data: any;
  closemodal: () => void;
  isDownloadingImage: boolean;
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  onClick,
  data,
  closemodal,
  isDownloadingImage,
}) => {
  const secondaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.secondaryColor
  );
  const handleDownloadImage = (photo: string) => {
    onClick(photo, data.title);
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="flex sm:w-760 w-11/12 sm:h-[738px] h-[600px] py-6 px-0 flex-col items-center rounded-18px overflow-y-auto">
        <div className="flex w-full pt-4 pr-4 pb-4 pl-10 justify-end items-start gap-10px  bg-lightBlack rounded-t-18px">
          <div className="flex justify-between items-center flex-1">
            <p className="text-white text-2xl font-normal">
              Scarica il contenuto
            </p>
            <img
              src={CloseIcon}
              alt="close icon"
              className="w-6 h-6"
              onClick={closemodal}
            />
          </div>
        </div>
        {data ? (
          <div className="flex flex-col w-full py-4 px-10 items-start gap-4 bg-white border border-Grey rounded-b-18px ">
            <div className="flex p-2 w-full items-center font-FiraSans text-black font-normal text-2xl">
              {data.title}
            </div>
            <div className="flex sm:flex-row flex-col items-start gap-5 w-full">
              {/* the image section */}
              <div className="flex items-start gap-5">
                {/* pick the image from props */}
                <img
                  className="flex w-400px h-400px items-center gap-2 p-10px flex-col rounded-18px object-cover"
                  alt="pic"
                  src={data.image}
                />
              </div>
              {/* input section */}
              <div className="flex flex-col justify-center items-center gap-6 flex-1">
                <div className="flex flex-col items-start gap-6">
                  {/* utente */}
                  {(data.user_details.facebook ||
                    data.user_details.tiktok ||
                    data.user_details.instagram) && (
                    <div className="flex flex-col items-start gap-2">
                      <div className=" flex font-FiraSans text-base font-medium text-black">
                        Utente:
                      </div>
                      {data.user_details.instagram && (
                        <div className="flex justify-center items-center gap-2">
                          <div className="w-18px h-18px justify-center items-center">
                            <img src={Instagram} alt="" className="" />
                          </div>
                          <div className="flex text-black text-base font-normal">
                            {data.user_details.instagram}
                          </div>
                        </div>
                      )}
                      {data.user_details.tiktok && (
                        <div className="flex justify-center items-center gap-2">
                          <div className="w-18px h-18px justify-center items-center">
                            <img src={TikTok} alt="" className="" />
                          </div>
                          <div className="flex text-black text-base font-normal">
                            {data.user_details.tiktok}
                          </div>
                        </div>
                      )}

                      {data.user_details.facebook && (
                        <div className="flex justify-center items-center gap-2">
                          <div className="w-18px h-18px justify-center items-center">
                            <img src={FaceBook} alt="" className="" />
                          </div>
                          <div className="flex text-black text-base font-normal">
                            {data.user_details.facebook}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* description */}
                  <div className="flex flex-col items-start gap-2 w-full">
                    <div className="text-black font-FiraSans text-base font-medium">
                      Descrizione
                    </div>
                    <div className="flex w-full flex-col justify- center items-start gap-10px font-FiraSans text-base font-normal">
                      {data.description}
                    </div>
                  </div>
                  {/* position */}
                  <div className="flex flex-row items-center gap-2">
                    <img
                      src={Position}
                      alt=""
                      className="w-18px h-18px pointer-events-none"
                    />
                    <div className="text-black font-FiraSans text-base font-normal underline">
                      {data.location}
                    </div>
                  </div>
                  {/* tags */}
                  <div className="flex flex-col items-start gap-2 w-full">
                    <div className="text-black text-base font-FiraSans font-medium">
                      Tag:
                    </div>
                    {/* for the tags */}
                    <div className="flex w-full pt-4 px-2 -mx-2 pb-4 items-start content-start gap-2 flex-wrap">
                      {data.tags.slice(0, 4)?.map((tag: any, index: number) => (
                        <Tag
                          key={index}
                          label={tag.name}
                          isSlected={true}
                          onClick={() => {}}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {isDownloadingImage ? (
                    <div className="flex items-center justify-center w-10 h-10">
                      <span className="button-loader w-full"></span>
                    </div>
                  ) : (
                    <PrimaryButton
                      type={"submit"}
                      buttonText={"Scarica"}
                      disabled={false}
                      onClick={() => handleDownloadImage(data.image)}
                    />
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        ) : (
          <div className="flex w-full h-[638px] bg-white items-center justify-center rounded-b-18px">
            <span
              className="loader-2"
              style={{ background: secondaryColor }}
            ></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadModal;
