// src/components/Modal.tsx
import React from "react";
import CloseIcon from "../../assets/svg/Close_LG.svg";
import PrimaryButton from "../primaryButton";
import SecondaryButton from "../secondaryButton";

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  avoidingAction: () => void;
  confirmingAction: () => void;
  confirmingActionIcon?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  avoidingAction,
  confirmingAction,
  confirmingActionIcon,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="flex flex-col items-start bg-white 2xl:w-5/12 md:w-6/12 w-11/12 rounded-18px">
        <div className="flex justify-end w-full p-6">
          <img
            src={CloseIcon}
            alt=""
            className="w-6 h-6 cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="p-10 mx-auto">
          <div className="flex flex-col justify-center w-full py-8 gap-y-4">
            <div className="text-center text-4xl">{title}</div>
            <div className="text-center text-xl">{description}</div>
          </div>
          <div className="flex justify-center w-full py-6 gap-x-6">
            <PrimaryButton
              buttonText={primaryButtonText}
              type={"button"}
              onClick={avoidingAction}
            />
            <SecondaryButton
              title={secondaryButtonText}
              icon={confirmingActionIcon ? confirmingActionIcon : ""}
              type={"button"}
              onClick={confirmingAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
