import React from "react";
import ProfilePicture from "../../assets/svg/profilePicture.svg";
import Instagram from "../../assets/svg/InstagramIcon.svg";
import TikTok from "../../assets/svg/TikTok.svg";
import FaceBook from "../../assets/svg/FaceBookIcon.svg";
import LogOutIcon from "../../assets/svg/LogOut.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reducers/userReducer";
import SecondaryButton from "../secondaryButton";
import { useNavigate } from "react-router-dom";

interface ProfileCardProps {
  profilePic?: string;
  completeName: string;
  instagram?: string;
  tiktok?: string;
  facebook?: string;
  onClickModifyButton?: any;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  profilePic,
  completeName,
  instagram,
  tiktok,
  facebook,
  onClickModifyButton,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleModifyButtonClick = () => {
    onClickModifyButton();
  };
  const LogOut = () => {
    localStorage.clear();
    dispatch(logout);
    navigate("/login");
    // window.location.href = "/welcome";
  };
  return (
    <div className="flex mx-6 my-8 gap-7 items-center flex-col w-255px rounded-lg bg-greyLight border border-greyBorder h-min">
      <div className="flex flex-col items-center gap-6 my-8">
        <div className="flex flex-col justify-center gap-8">
          <div className="flex flex-col items-center gap-4">
            <div className="rounded-container">
              <img
                alt="profile pic"
                className="w-full h-full object-cover"
                src={profilePic ? profilePic : ProfilePicture}
              />
            </div>

            <p className="text-black font-FiraSans text-xl font-normal">
              {completeName}
            </p>
          </div>
          <div className="flex flex-col items-center gap-2">
            {instagram !== "" && (
              <div className="flex justify-center items-start gap-2">
                <div className="flex items-center justify-center">
                  <img
                    src={Instagram}
                    alt="instagram icon"
                    className=" w-18px h-18px"
                  />
                </div>
                <div className="text-black text-base font-normal mt-[-4px]">
                  {instagram}
                </div>
              </div>
            )}

            {tiktok !== "" && (
              <div className="flex justify-center items-start gap-2">
                <div className="flex items-center justify-center">
                  <img
                    src={TikTok}
                    alt="TikTok icon"
                    className=" w-18px h-18px"
                  />
                </div>
                <div className="text-black text-base font-normal mt-[-4px]">
                  {tiktok}
                </div>
              </div>
            )}

            {facebook !== "" && (
              <div className="flex justify-center items-start gap-2">
                <div className="flex items-center justify-center">
                  <img
                    src={FaceBook}
                    alt="Facebook icon"
                    className=" w-18px h-18px"
                  />
                </div>
                <div className="text-black text-base font-normal mt-[-4px]">
                  {facebook}
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className="flex py-1 px-4 justify-center items-center gap-10px text-white text-center font-FiraSans text-xs font-normal uppercase bg-RedPantone rounded-30px"
          onClick={handleModifyButtonClick}
        >
          MODIFICA PROFILO
        </button>
        <SecondaryButton
          title={"Log Out"}
          icon={LogOutIcon}
          type={"button"}
          onClick={LogOut}
        />
      </div>
    </div>
  );
};

export default ProfileCard;
