// Input.tsx
import React, { ChangeEvent, FocusEvent, useId } from "react";
import Required from "../../assets/svg/required.svg";
import { twMerge } from "tailwind-merge";

type InputType =
  | "text"
  | "password"
  | "email"
  | "number"
  | "date"
  | "checkbox"
  | "radio"
  | "file"
  | "submit"
  | "reset"
  | "button";

interface InputProps {
  name?: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  imageSrc?: string;
  required?: boolean;
  type?: InputType;
  className?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  imageSrc,
  required = false,
  type = "text",
  className,
}) => {
  const inputId = useId();
  return (
    <div className="relative w-full">
      <input
        id={inputId}
        name={name}
        type={type}
        className={twMerge(
          "bg-gray-50 border border-gray-300 text-black text-sm rounded-lg w-full p-2 outline-none",
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {imageSrc && (
        <div className="absolute inset-y-0 end-0 p-4 flex items-center ps-3.5 pointer-events-none">
          <img src={imageSrc} alt="" className="w-18px h-18px" />
        </div>
      )}
      {required && (
        <div className="absolute inset-y-0 end-0 p-2 flex items-start ps-3.5 pointer-events-none">
          <img src={Required} alt="" className="w-2 h-2" />
        </div>
      )}
    </div>
  );
};

export default Input;
