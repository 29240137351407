import ProfileEmpty from "../../assets/svg/profile-empty.svg";
import Hide from "../../assets/svg/Hide.svg";
import Show from "../../assets/svg/Show.svg";
import FaceBook from "../../assets/svg/FaceBookIcon.svg";
import Instagram from "../../assets/svg/InstagramIcon.svg";
import TikTok from "../../assets/svg/TikTok.svg";
import CloseIcon from "../../assets/svg/Close_LG.svg";
import BackButton from "../../assets/svg/Arrow_Left_LG.svg";
import PrimaryButton from "../primaryButton";
import Input from "../input";
import { useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { checkEmailAPI, registerAPI } from "../../api/api";
import { useNavigate } from "react-router-dom";
import BaseBackground from "../../pages/layout/BaseBackground";
import { useMutation, useQueryClient } from "react-query";
import Toast from "../toast";
import { TermsArray } from "../../data/terms";

const SignUp = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const [toastStatus, setToastStatus] = useState<string>("none");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const initialData = {
    name: "",
    picture: "",
    email: "",
    instagram: "",
    tiktok: "",
    facebook: "",
    password: "",
    repeatPassword: "",
    tos: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Il nome è obbligatorio"),
    picture: Yup.string(),
    email: Yup.string()
      .email("E-mail non valido")
      .required("L'e-mail è obbligatoria"),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    facebook: Yup.string(),
    password: Yup.string()
      .min(6, "La password deve essere di almeno 6 caratteri")
      .required("Password è obbligatorio"),
    tos: Yup.boolean().isTrue().required("You need to accept terms of service"),
    repeatPassword: Yup.string()
      .required("Repeat password è obbligatorio")
      .oneOf([Yup.ref("password")], "Le due password non corrispondono"),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePass = () => {
    setShowPass(!showPass);
  };

  const submitUserData = async (data: any) => {
    registerAPI
      .register(data)
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/theme");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleSignUpResult = (color: string, message: string) => {
    setToastMessage(message);
    setToastStatus(color);
    // Reset toast status and message after 5 seconds
    setTimeout(() => {
      setToastStatus("none");
      setToastMessage("");
    }, 5000);
  };

  const { mutateAsync: signUpMutation, isLoading } = useMutation({
    mutationFn: registerAPI.register,
    onSuccess: () => {
      navigate("/theme");
      handleSignUpResult("success", "Account creato!");
    },
    onError: () => {
      handleSignUpResult(
        "error",
        "Ops! Qualcosa è andato storto, riprova più tardi."
      );
    },
  });

  return (
    <BaseBackground>
      <>
        {toastStatus === "success" && (
          <Toast color={toastStatus} message={toastMessage} />
        )}
        {toastStatus === "error" && (
          <Toast color={toastStatus} message={toastMessage} />
        )}
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            setLoading(true);
            values.role = "USER";
            signUpMutation(values);
          }}
          enableReinitialize={true}
        >
          {(formik) => {
            const uploadPicture = () => {
              fileInputRef.current?.click();
            };

            return (
              <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
                <Form>
                  <div className=" flex items-center justify-center z-50">
                    <div className="bg-white flex px-10 sm:py-8 py-2 items-center sm:gap-4 gap-3 flex-col rounded-18px sm:w-[600px] w-[350px] min-h-[80vh] max-h-[95vh] overflow-y-auto relative">
                      {!showTerms && (
                        <>
                          <img
                            src={CloseIcon}
                            alt="Close"
                            className="absolute top-4 right-4 cursor-pointer"
                            onClick={() => {
                              navigate("/welcome");
                            }}
                          />
                          <div className="flex flex-col items-center sm:gap-4 gap-2">
                            {/* profile pic */}
                            <div className="rounded-container">
                              <img
                                src={formik.values.picture || ProfileEmpty}
                                alt=""
                                className="rounded-image object-cover"
                              />
                            </div>
                            <p
                              className="flex h-5 items-center  gap-2 font-FiraSans sm:text-base text-xs font-normal text-black underline"
                              onClick={uploadPicture}
                            >
                              <label>Scegli una foto</label>
                            </p>
                            <input
                              id="imageUpload"
                              ref={fileInputRef}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(event: any) => {
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  const file = event.target.files[0];
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    formik.setFieldValue(
                                      "picture",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                            />
                          </div>
                          <div className="flex flex-col justify-center items-center sm:w-[459px] w-full space-y-2">
                            <div className="flex flex-row gap-6 w-full items-center">
                              <div className="sm:text-base text-xs font-FiraSans text-black w-4/12 font-semibold">
                                Nome Cognome:
                              </div>
                              <div className="w-6/12">
                                <Input
                                  placeholder={"Inserisci nome e cognome"}
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  // this on blur makes touched property get updated
                                  onBlur={formik.handleBlur}
                                  name="name"
                                  required={true}
                                />
                                {formik.errors.name && formik.touched.name && (
                                  <p className="flex text-xs ml-3 py-1 text-RedPantone font-FiraSans font-semibold">
                                    {formik.errors.name.toString()}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-row gap-6 w-full items-center">
                              <div className="sm:text-base text-xs font-FiraSans text-black w-4/12 font-semibold">
                                Email:
                              </div>
                              <div className="w-6/12">
                                <Input
                                  placeholder={"Inserisci email"}
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  // this on blur makes touched property get updated
                                  onBlur={async (e) => {
                                    formik.handleBlur(e);
                                    const email = e.target.value;
                                    if (email) {
                                      const emailExists =
                                        await checkEmailAPI.checkEmail({
                                          email: email,
                                        });
                                      if (emailExists.data.exists) {
                                        formik.setFieldError(
                                          "email",
                                          "L'utente con questa email esiste già"
                                        );
                                      }
                                    }
                                  }}
                                  name="email"
                                  required={true}
                                  type={"email"}
                                />
                                {formik.errors.email &&
                                  formik.touched.email && (
                                    <p className="flex text-xs ml-3 py-1 text-RedPantone font-FiraSans font-semibold">
                                      {formik.errors.email.toString()}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="flex flex-row gap-6 w-full">
                              <div className="sm:text-base text-xs font-FiraSans text-black w-4/12 font-semibold justify-start">
                                Profili social:
                              </div>
                              <div className="flex flex-col w-6/12 gap-2">
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={Instagram}
                                    onClick={() => {}}
                                  />
                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.instagram}
                                    onChange={formik.handleChange}
                                    name="instagram"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={TikTok}
                                    onClick={() => {}}
                                  />
                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.tiktok}
                                    onChange={formik.handleChange}
                                    name="tiktok"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={FaceBook}
                                    onClick={() => {}}
                                  />
                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.facebook}
                                    onChange={formik.handleChange}
                                    name="facebook"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row gap-6 w-full items-center">
                              <div className="sm:text-base text-xs font-FiraSans text-black w-4/12 font-semibold">
                                Scegli password:
                              </div>
                              <div className="w-6/12">
                                <Input
                                  placeholder={"Inserisci password"}
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  // this on blur makes touched property get updated
                                  onBlur={formik.handleBlur}
                                  name="password"
                                  required={true}
                                  type={showPassword ? "text" : "password"}
                                />
                                {formik.touched.password &&
                                  formik.errors.password && (
                                    <p className="flex text-xs ml-3 py-1 text-RedPantone font-FiraSans font-semibold">
                                      {formik.errors.password.toString()}
                                    </p>
                                  )}
                              </div>
                              <img
                                className="flex flex-1"
                                alt=""
                                src={showPassword ? Show : Hide}
                                onClick={togglePasswordVisibility}
                              />
                            </div>
                            <div className="flex flex-row gap-6 w-full items-center">
                              <div className="sm:text-base text-xs font-FiraSans text-black w-4/12 font-semibold">
                                Ripeti password:
                              </div>
                              <div className="w-6/12">
                                <Input
                                  placeholder={"Ripeti password"}
                                  value={formik.values.repeatPassword}
                                  onChange={formik.handleChange}
                                  // this on blur makes touched property get updated
                                  onBlur={formik.handleBlur}
                                  name="repeatPassword"
                                  required={true}
                                  type={showPass ? "text" : "password"}
                                />
                                {formik.touched.repeatPassword &&
                                  formik.errors.repeatPassword && (
                                    <p className="flex text-xs ml-3 py-1 text-RedPantone font-FiraSans font-semibold">
                                      {formik.errors.repeatPassword.toString()}
                                    </p>
                                  )}
                              </div>
                              <img
                                className="flex flex-1"
                                alt=""
                                src={showPass ? Show : Hide}
                                onClick={togglePass}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 sm:w-449px items-start">
                            <div className="flex">
                              <input
                                type="checkbox"
                                checked={formik.values.tos}
                                onChange={formik.handleChange}
                                name="tos"
                                className=""
                              />
                            </div>
                            <p
                              className="flex font-black text-justify sm:text-sm text-xs font-FiraSans underline"
                              onClick={() => {
                                setShowTerms(true);
                              }}
                            >
                              I confirm that I have read and accept the terms
                              and conditions and privacy policy.
                            </p>
                          </div>
                          <p className="flex text-black text-center font-FiraSans font-light sm:text-[13px] text-[10px] items-center">
                            I campi contrassegnati dal simbolo * sono da
                            compilare obbligatoriamente
                          </p>
                          {isLoading ? (
                            <div className="flex items-center justify-center w-10 h-10">
                              <span className="button-loader w-full"></span>
                            </div>
                          ) : (
                            <PrimaryButton
                              buttonText={"Iscriviti"}
                              type={"submit"}
                              disabled={!formik.isValid}
                            />
                          )}
                        </>
                      )}
                      {showTerms && (
                        <>
                          <img
                            src={BackButton}
                            alt="Close"
                            className="absolute top-4 left-4 cursor-pointer w-4 h-4"
                            onClick={() => setShowTerms(false)}
                          />
                          <div className="flex sm:w-475px w-[300px] flex-col justify-center items-center gap-4 flex-1">
                            <div className="w-full text-center text-black font-FiraSans text-2xl font-normal ">
                              Terms & Conditions <br /> and Privacy Policy
                            </div>
                            <div className="flex flex-col w-full sm:h-[455px] h-min p-[10px] justify-center items-start sm:gap-[10px] gap-[5px]">
                              <div
                                className="flex h-min flex-col"
                                style={{ overflowY: "auto" }}
                              >
                                {TermsArray &&
                                  TermsArray.map((term, i) => (
                                    <p
                                      className="font-FiraSans text-base"
                                      key={i}
                                    >
                                      <span className="font-bold text-lgfont-FiraSans">
                                        {term.title}
                                      </span>
                                      <br />
                                      {term.data}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <PrimaryButton
                            buttonText={"Ho Capito"}
                            type={"submit"}
                            disabled={false}
                            onClick={() => {
                              setShowTerms(false);
                              formik.setFieldValue("tos", true);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </>
    </BaseBackground>
  );
};

export default SignUp;
