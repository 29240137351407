import { useEffect, useRef, useState } from "react";
import AddIcon from "../../assets/svg/AddIcon.svg";
import CloseIcon from "../../assets/svg/CloseIcon.svg";
import PrimaryButton from "../primaryButton";
import Position from "../../assets/svg/PositionLIcon.svg";
import SaveIcon from "../../assets/svg/SaveIconM.svg";
import Tag from "../Tag";
import { InitialData } from "../../models/initialData";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { createPlaceAPI, getTagsAPI } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useOnMobile from "../../hook/useOnMobile";

interface AddPhotoCardProps {
  setToastStatus: React.Dispatch<React.SetStateAction<string>>;

  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
}

const AddPhotoCard: React.FC<AddPhotoCardProps> = ({
  setToastStatus,
  setToastMessage,
}) => {
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const maxLength = 200;
  const [tagsList, setTagsList] = useState([]);
  const secondaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.secondaryColor
  );
  const isMobile = useOnMobile();

  const handlePlaceAdditionResult = (value: string, message: string) => {
    setToastStatus(value);
    setToastMessage(message);
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Image è obbligatorio"),
    title: Yup.string().required("titolo è obbligatorio"),
    description: Yup.string().required("Il descrizione è obbligatorio"),
    location: Yup.string().required("posto è obbligatorio"),
    tags: Yup.array().min(1, "è richiesto almeno un Tag"),
  });

  const initialData: InitialData = {
    image: "",
    title: "",
    description: "",
    location: "",
    tags: [],
  };

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    getTagsAPI
      .getTags()
      .then((res) => {
        setTagsList(res.data.tags);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutateAsync: createPlaceMutation } = useMutation({
    mutationFn: createPlaceAPI.createPlace,
    onSuccess: () => {
      queryClient.invalidateQueries("places");
      // add logic to show
      handlePlaceAdditionResult(
        "success",
        "Il contenuto è stato caricato con successo!"
      );
    },
    onError: () => {
      handlePlaceAdditionResult(
        "error",
        "Ops! Qualcosa è andato storto, riprova più tardi."
      );
    },
  });

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values: InitialData, { resetForm }) => {
        try {
          await createPlaceMutation(values);
          setModalIsOpen(false);
          resetForm();
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => {
        // handleFormChange(formik.values);
        const openCamera = () => {
          fileInputRef.current?.click();
        };
        const handleFileChange = (event: any) => {
          const file = event.target.files?.[0];
          if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
              // Set the image in the state
              formik.setFieldValue("image", reader.result);
            };

            reader.readAsDataURL(file);
          }
          setModalIsOpen(true);
        };
        const tagClick = (tagObject: any) => {
          if (formik.values.tags.includes(tagObject)) {
            formik.setFieldValue(
              "tags",
              formik.values.tags.filter((tag) => tag !== tagObject)
            );
          } else {
            formik.setFieldValue("tags", [...formik.values.tags, tagObject]);
          }
        };
        const isTagSelected = (currentTag: any): boolean => {
          return formik.values.tags.includes(currentTag);
        };

        return (
          <Form>
            <div>
              <div
                className="flex justify-center mb-4 w-284px h-[409px] p-10px flex-col items-center gap-2 rounded-18px border border-white  shadow-tagShadow"
                style={{ background: secondaryColor }}
                onClick={openCamera}
              >
                <img className="" src={AddIcon} alt="" />
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  // name={`image`}
                  // capture="user"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              {modalIsOpen && (
                <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="flex flex-col items-start bg-white">
                    {/* modal top */}
                    <div className="flex w-760px pt-4 pr-4 pb-4 pl-10 justify-end items-start gap-10px  bg-lightBlack">
                      <div className="flex justify-between items-center flex-1">
                        <p className="text-white text-2xl font-normal">
                          Publica un nuovo contenuto
                        </p>
                        <img
                          src={CloseIcon}
                          alt="close icon"
                          className="w-6 h-6"
                          onClick={() => {
                            setModalIsOpen(false);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full py-4 px-10 items-start gap-4 bg-white border border-Grey">
                      <input
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Aggiungi un titolo..."
                        className="flex p-2 bg-greyLight w-full items-center font-FiraSans placeholder:font-FiraSans text-black placeholder:text-black placeholder:font-normal font-normal placeholder:text-2xl text-2xl outline-none"
                      />
                      {formik.errors.title && formik.touched.title && (
                        <p className="flex text-RedPantone font-FiraSans font-semibold">
                          {formik.errors.title.toString()}
                        </p>
                      )}
                      <div className="flex flex-row items-start gap-5 w-full">
                        {/* the image section */}
                        <div className="flex items-start gap-5">
                          <img
                            className="flex w-400px h-400px items-center gap-2 p-10px flex-col rounded-18px object-cover"
                            // src={formik.values.image}
                            src={formik.values.image}
                            alt="file choosen"
                          />
                        </div>
                        {/* input section */}
                        <div className="flex flex-col justify-center items-center gap-4 flex-1">
                          <div className="flex flex-col items-start gap-4">
                            <div className="flex flex-col">
                              <textarea
                                className="flex w-260px h-262px p-10px items-start gap-10px rounded-lg border border-Grey bg-greyLight outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-base text-base placeholder:font-normal font-normal"
                                placeholder="Aggiungi una descrizione..."
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={
                                  formik.values.description.length > 200
                                }
                              ></textarea>
                              <p className="text-xs font-normal text-black text-right font-FiraSans">
                                {formik.values.description.length}/{maxLength}{" "}
                                characters
                              </p>
                              {formik.errors.description &&
                                formik.touched.description && (
                                  <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                                    {formik.errors.description.toString()}
                                  </p>
                                )}
                            </div>
                            <div className="flex flex-col">
                              <div className="relative w-260px">
                                <input
                                  type="text"
                                  id="input-group-1"
                                  className="bg-white border border-Grey rounded-lg w-full p-2 outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-base text-base placeholder:font-normal font-normal"
                                  placeholder="Aggiungi un luogo"
                                  name="location"
                                  value={formik.values.location}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <div className="absolute inset-y-0 end-0 p-4 flex items-center ps-3.5 pointer-events-none">
                                  <img
                                    src={Position}
                                    alt=""
                                    className="w-18px h-18px"
                                  />
                                </div>
                              </div>
                              {formik.errors.location &&
                                formik.touched.location && (
                                  <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                                    {formik.errors.location.toString()}
                                  </p>
                                )}
                            </div>
                            <div className="flex flex-col items-center w-260px min-h-230px bg-greyLight rounded-lg">
                              <div className="flex flex-col w-full">
                                <div className="relative w-full">
                                  <input
                                    type="text"
                                    id="input-group-2"
                                    disabled={true}
                                    className="bg-white border border-Grey rounded-lg w-full p-2 outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-base text-base placeholder:font-normal font-normal"
                                    placeholder="Aggiungi un tag"
                                  />
                                  <div className="absolute inset-y-0 end-0 p-4 flex items-center ps-3.5 pointer-events-none">
                                    <img
                                      src={SaveIcon}
                                      alt=""
                                      className="w-18px h-18px"
                                    />
                                  </div>
                                </div>
                                {formik.errors.tags && (
                                  <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                                    {formik.errors.tags.toString()}
                                  </p>
                                )}
                              </div>

                              {/* for the tags */}
                              <div className="grid grid-cols-3 w-full pt-4 px-4 -mx-2 pb-4 items-start content-start gap-2 flex-wrap overflow-x-auto">
                                {tagsList?.map((tag: any, index) => (
                                  <Tag
                                    key={index}
                                    label={tag.name}
                                    isSlected={isTagSelected(tag)}
                                    onClick={() => tagClick(tag)}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-center">
                            <PrimaryButton
                              type={"submit"}
                              buttonText={"PUBBLICA"}
                              disabled={!formik.isValid || formik.isSubmitting}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AddPhotoCard;
