export interface ThemeColorInterface {
  id: number;
  primaryColor: string;
  secondaryColor: string;
}

export const ThemeColors: ThemeColorInterface[] = [
  {
    id: 1,
    primaryColor: "#DA291C",
    secondaryColor: "#A70000",
  },
  {
    id: 2,
    primaryColor: "#f8d43c",
    secondaryColor: "#c8a434",
  },
  {
    id: 3,
    primaryColor: "#c8a434",
    secondaryColor: "#987c4c",
  },
  {
    id: 4,
    primaryColor: "#69B3E7",
    secondaryColor: "#3680B4",
  },
  {
    id: 5,
    primaryColor: "#9AB9AD",
    secondaryColor: "#67867A",
  },
  {
    id: 6,
    primaryColor: "#3EB1C8",
    secondaryColor: "#0B7E95",
  },
  {
    id: 7,
    primaryColor: "#415162",
    secondaryColor: "#0E1E2F",
  },
];
