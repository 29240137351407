import React, { useState } from "react";
import SearchIcon from "../../assets/svg/SearchBlack.svg";

interface SearchBarProps {
  onSearch: (searchText: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value || "";
    setSearchText(text);
    onSearch(text);
  };

  return (
    <div className="relative w-284px shadow-tagShadow gap-2">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <img src={SearchIcon} alt="" className="ml-2" />
      </div>
      <input
        type="text"
        value={searchText}
        onChange={handleInputChange}
        className="block w-full py-2 pl-12 pr-4 text-base placeholder:text-base font-normal placeholder:font-normal text-black placeholder:text-black border border-Grey rounded-40px bg-white outline-none font-FiraSans placeholder:font-FiraSans"
        placeholder="Search"
      />
    </div>
  );
};

export default SearchBar;
