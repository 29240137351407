import React from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import BaseBackground from "../pages/layout/BaseBackground";

export default function Welcome() {
  const navigate = useNavigate();
  return (
    <BaseBackground>
      <div className="flex flex-col items-center gap-10 h-fit p-8">
        <div className="flex flex-col sm:max-w-[950px] m-auto">
          <span className="sm:max-w-[950px] w-full text-white text-center font-FiraSans sm:text-40px text-32px font-medium">
            Benvenuto!
          </span>
          <p className="sm:max-w-[950px] w-full text-white text-center font-FiraSans sm:text-2xl text-base font-normal">
            <br />
            Siamo entusiasti di darti il benvenuto sulla nostra piattaforma, il
            luogo dove la bellezza di Jesolo prende vita attraverso i tuoi
            occhi.
            <br />
            <br />
            Qui, sei il narratore della storia di questa affascinante città
            balneare, e siamo pronti ad esplorare ogni suo angolo con te.
          </p>
        </div>
        <div className="flex items-center sm:justify-center sm:space-x-10 sm:space-y-0 space-y-4 sm:flex-row flex-col">
          <button
            disabled={false}
            type={"button"}
            onClick={() => {
              navigate("/sign-up");
            }}
            className={twMerge(
              "flex px-8 py-2 justify-center items-center gap-10px text-white text-center font-FiraSans text-2xl font-normal uppercase bg-RedPantone rounded-30px hover:underline"
            )}
          >
            {"Iscriviti"}
          </button>
          <button
            disabled={false}
            type={"button"}
            onClick={() => {
              navigate("/login");
            }}
            className={twMerge(
              "flex px-8 py-2 justify-center items-center gap-10px text-RedPantone text-center font-FiraSans text-2xl font-normal uppercase bg-white rounded-30px hover:underline"
            )}
          >
            {"Accedi"}
          </button>
        </div>
      </div>
    </BaseBackground>
  );
}
