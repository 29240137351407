import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import UserLayout from "./pages/layout/userLayout";
import AdminLayout from "./pages/layout/adminLayout";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import SignUp from "./components/signUp";
import Login from "./components/login";
import Welcome from "./components/Welcome";
import Navbar from "./components/navbar";
import Theme from "./components/theme";
import useOnMobile from "./hook/useOnMobile";

function App() {
  const role = useSelector(
    (rootState: RootState) => rootState.userReducer.role
  );
  const primaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.primaryColor
  );
  const isMobile = useOnMobile();

  return (
    <div
      className="flex flex-col  h-screen text-black"
      style={{ background: primaryColor }}
    >
      {isMobile ? <></> : <Navbar></Navbar>}
      <div className="sm:mt-[88px]  w-full h-full">
        <Router>
          <Routes>
            {role === "ADMIN" && <Route path="/*" element={<AdminLayout />} />}
            {role === "USER" && <Route path="/*" element={<UserLayout />} />}
            {!role && (
              <Route path="/*" element={<Navigate to="/welcome" replace />} />
            )}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/theme" element={<Theme />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
