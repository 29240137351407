import Position from "../../assets/svg/PositionLIcon.svg";
import Download from "../../assets/svg/downloadIcon.svg";
import Delete from "../../assets/svg/deleteIcon.svg";
import Tag from "../Tag";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface PhotoCardProps {
  id: string;
  photo: string;
  title: string;
  position: string;
  tags: string[];
  onClick?: () => void;
  openModal?: () => void;
  deleteCard: (cardId: string) => void;
}

const PhotoCard: React.FC<PhotoCardProps> = ({
  id,
  photo,
  title,
  position,
  tags,
  onClick,
  openModal,
  deleteCard,
}) => {
  const handleCardDeletion = (id: string) => {
    if (id) {
      deleteCard?.(id);
    }
  };

  const tagClicked = (tag: string) => {
    console.log(tag, "");
  };
  const isAdmin = useSelector(
    (rootState: RootState) => rootState.userReducer.isAdmin
  );

  return (
    <>
      <div
        className={twMerge(
          "flex w-284px pb-0 flex-col items-center gap-2 h-[462px] rounded-18px border  bg-white shadow-photoCardShadow",
          false ? "border-EsitoPositivo" : "border-greyBorder"
        )}
      >
        <div
          className={twMerge(
            "flex w-284px p-10px items-start rounded-14px",
            isAdmin ? "flex-col items-center relative" : ""
          )}
        >
          {isAdmin && (
            <div className="absolute top-5 right-5  h-[80px] w-[40px] flex-col space-y-2 z-10">
              <div className="flex bg-white rounded-full items-center justify-center w-[45px] h-[45px] px-[10px] py-[4px]">
                <img
                  src={Delete}
                  alt=""
                  className=""
                  onClick={() => {
                    handleCardDeletion(id);
                  }}
                />
              </div>
              <div className="flex bg-white rounded-full items-center justify-center w-[45px] h-[45px] px-[10px] py-[4px]">
                <img src={Download} alt="" className="" onClick={openModal} />
              </div>
            </div>
          )}
          <img
            src={photo}
            alt=""
            className="flex w-264px h-264px justify-center items-center object-cover rounded-14px"
            onClick={onClick}
          />
        </div>
        <div className="flex flex-col items-center gap-4" onClick={onClick}>
          <div className="flex w-264px flex-col items-start gap-2">
            <div className="flex flex-col gap-2 self-stretch">
              <div className="flex flex-col items-start self-stretch text-black font-FiraSans text-base font-normal uppercase">
                {title}
              </div>
              <div className="flex flex-row items-start gap-4 self-stretch">
                <div className="">
                  <img src={Position} className="w-18px h-18px" alt="" />
                </div>
                <div className="text-black font-FiraSans text-xs font-normal">
                  {position}
                </div>
              </div>
            </div>
            {/* somthing is making the whole page to scroll vertically and horizontally if the tags are many */}
            <div className="flex items-center gap-2 p-2 flex-wrap">
              {tags?.map((tag: any, key) => {
                if (key > 4) {
                  return (
                    key === 5 && (
                      <div className="flex items-center text-black underline cursor-pointer">
                        Mostra tutto
                      </div>
                    )
                  );
                } else {
                  return (
                    <Tag
                      key={tag.id}
                      label={tag.name}
                      isSlected={true}
                      onClick={tagClicked}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PhotoCard;
