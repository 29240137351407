import { useState } from "react";
import Position from "../../assets/svg/PositionLIcon.svg";
import Tag from "../Tag";
import Delete from "../../assets/svg/deleteIcon.svg";
import { twMerge } from "tailwind-merge";
import DeleteIcon from "../../assets/svg/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { deletePlaceAPI } from "../../api/api";
import ConfirmModal from "../confirmModal";
import Toast from "../toast";
import useOnMobile from "../../hook/useOnMobile";
interface PhotoCardDetailProps {
  photo: string;
  title: string;
  description?: string;
  position: string;
  tags: string[];
  cardId: string;
}

const PhotoCardDetail: React.FC<PhotoCardDetailProps> = ({
  photo,
  title,
  position,
  tags,
  description,
  cardId,
}) => {
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const isMobile = useOnMobile();

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen((prev) => !prev);
  };
  const cardClicked = () => {
    setTrigger(true);
    setTimeout(() => {
      setTrigger(false);
    }, 5000);
  };
  const tagClicked = (tag: string) => {
    console.log("");
  };

  const handlePlaceAdditionResult = (color: string, messsage: string) => {
    return <Toast message={messsage} color={color} />;
  };

  const { mutateAsync: deletePlaceMutation } = useMutation(
    (cardId: string) => deletePlaceAPI.deletePlace(cardId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("places");
        handlePlaceAdditionResult(
          "success",
          "hai eliminato con successo il luogo!"
        );
        navigate("/");
      },
      onError: () => {
        handlePlaceAdditionResult(
          "error",
          "Ops! Qualcosa è andato storto, riprova più tardi."
        );
      },
    }
  );

  return (
    <div
      className={twMerge(
        "flex w-full pb-0 flex-col items-center gap-2 rounded-18px border bg-white shadow-photoCardShadow",
        trigger ? "border-EsitoPositivo" : "border-greyBorder"
      )}
      onClick={cardClicked}
    >
      <div className="relative w-full">
        <div className="absolute top-10 right-10">
          <div
            className={twMerge(
              "flex bg-white rounded-full items-center justify-center",
              isMobile ? "w-9 h-9" : " w-14 h-14"
            )}
          >
            <img
              src={Delete}
              alt=""
              className={twMerge("cursor-pointer", isMobile ? "w-7" : " w-9")}
              onClick={toggleDeleteModal}
            />
          </div>
        </div>

        <div className="flex w-full sm:w-688px h-[300px] sm:h-[688px] p-10px items-center justify-center rounded-18px">
          <img
            src={photo}
            alt=""
            className="flex w-full h-[300px] sm:pt-0 pt-2 sm:h-full items-center justify-center object-cover rounded-14px "
          />
        </div>
      </div>

      <div className="flex flex-col items-center sm:gap-8 gap-1 w-full sm:w-632px px-4 sm:px-0">
        <div className="flex w-full sm:w-632px flex-col items-start gap-1 ">
          <div className="flex flex-col w-full items-start self-stretch text-black font-FiraSans sm:text-40px text-base uppercase font-normal">
            {title}
          </div>
          <div className="flex flex-row items-start gap-4 self-stretch">
            <div className="sm:mt-1">
              <img src={Position} className="w-5 h-5" alt="" />
            </div>
            <div className="text-black font-FiraSans sm:text-xl text-sm font-normal">
              {position}
            </div>
          </div>
        </div>
        {/* something is making the whole page to scroll vertically and horizontally if the tags are many */}
        <div className="flex flex-wrap gap-1 self-stretch mb-10px sm:w-632px w-full">
          {tags?.map((tag) => {
            return (
              <Tag
                key={tag}
                label={tag}
                isSlected={true}
                onClick={tagClicked}
              />
            );
          })}
        </div>
        <div className="flex flex-col sm:gap-x-10px gap-2 self-stretch sm:mb-6 mb-4">
          <div className="text-black font-FiraSans sm:text-2xl text-sm font-normal">
            Descrizione
          </div>
          <div className="flex font-FiraSans sm:text-base text-xs font-normal text-black">
            {description}
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        title={"Sei sicuro di voler eliminare la foto?"}
        description={
          "Una volta eliminata la foto sarà cancellata definitivamente."
        }
        primaryButtonText={"TORNA ALLA HOME"}
        secondaryButtonText={"Elimina"}
        avoidingAction={() => navigate("/")}
        confirmingAction={() => deletePlaceMutation(cardId)}
        confirmingActionIcon={DeleteIcon}
      />
    </div>
  );
};
export default PhotoCardDetail;
