import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { RootState } from "../../redux/store";

interface ToastProps {
  message: string;
  color: string;
}

const Toast: React.FC<ToastProps> = ({ message, color }) => {
  const [showToast, setShowToast] = useState(false);
  const isAdmin = useSelector(
    (rootState: RootState) => rootState.userReducer.isAdmin
  );

  useEffect(() => {
    setShowToast(true);
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={twMerge(
        `absolute gap-10px p-10px top-0 left-0 right-0 text-white flex items-center z-50`,
        showToast ? "block" : "hidden",
        color === "success" ? "bg-EsitoPositivo" : "bg-Errore",
        isAdmin ? "" : "",
        "p-10px font-FiraSans text-base font-normal"
      )}
    >
      {message}
    </div>
  );
};

export default Toast;
