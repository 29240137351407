import { Routes, Route } from "react-router-dom";
import Sidebar from "../../../components/sidebar";
import Home from "../../Home";
import CardDetails from "../../CardDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useOnMobile from "../../../hook/useOnMobile";
import MobileBar from "../../../components/sidebar/MobileBar";
import Toast from "../../../components/toast";
import { useState } from "react";

const UserLayout = () => {
  const [toastStatus, setToastStatus] = useState<string>("none");
  const [toastMessage, setToastMessage] = useState<string>("");
  const isMobile = useOnMobile();
  const primaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.primaryColor
  );

  const showToast = (color: string, messsage: string) => {
    setToastMessage(messsage);
    setToastStatus(color);

    // Reset toast status and message after 5 seconds
    setTimeout(() => {
      setToastStatus("none");
      setToastMessage("");
    }, 5000);
  };
  return (
    <>
      {toastStatus === "success" && (
        <Toast color={toastStatus} message={toastMessage} />
      )}
      {toastStatus === "error" && (
        <Toast color={toastStatus} message={toastMessage} />
      )}
      <div
        className="flex h-full"
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        {isMobile ? (
          <MobileBar showToast={showToast} />
        ) : (
          <Sidebar showToast={showToast} />
        )}
        <div
          className="flex-1 h-min"
          style={{
            background: isMobile ? "white" : primaryColor,
            marginLeft: isMobile ? "" : "303px",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/card-details/:id" element={<CardDetails />} />
          </Routes>
        </div>
      </div>
    </>
  );
};
export default UserLayout;
