import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  isAdmin: boolean;
  role: string;
  name: string;
  picture: string;
  tiktok: string;
  instagram: string;
  facebook: string;
  primaryColor: string;
  secondaryColor: string;
}

const initialState: UserState = {
  isAdmin: false,
  role: "",
  name: "",
  picture: "",
  tiktok: "",
  instagram: "",
  facebook: "",
  primaryColor: "#9AB9AD",
  secondaryColor: "#7c948c",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAdminValue: (state: any, action: PayloadAction<any>) => {
      state.isAdmin = action.payload;
    },
    setRole: (state: any, action: PayloadAction<any>) => {
      state.role = action.payload;
    },
    setName: (state: any, action: PayloadAction<any>) => {
      state.name = action.payload;
    },
    setPicture: (state: any, action: PayloadAction<any>) => {
      state.picture = action.payload;
    },
    setTikTok: (state: any, action: PayloadAction<any>) => {
      state.tiktok = action.payload;
    },
    setFaceBook: (state: any, action: PayloadAction<any>) => {
      state.facebook = action.payload;
    },
    setInstagram: (state: any, action: PayloadAction<any>) => {
      state.instagram = action.payload;
    },
    setToInitialState: (state: any) => {
      state = initialState;
    },
    setPrimaryColor: (state: any, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state: any, action: PayloadAction<any>) => {
      state.secondaryColor = action.payload;
    },
    logout: (state: any) => {
      state.role = "";
      state.name = "";
      state.picture = "";
      state.tiktok = "";
      state.instagram = "";
      state.facebook = "";
      localStorage.clear()
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsAdminValue,
  setRole,
  setName,
  setPicture,
  setTikTok,
  setInstagram,
  setFaceBook,
  setToInitialState,
  setPrimaryColor,
  setSecondaryColor,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
