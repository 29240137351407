import React, { useEffect, useRef, useState } from "react";
import FilterIcon from "../../assets/svg/FIlterBlack.svg";
import PrimaryButton from "../primaryButton";
import Tag from "../Tag";

interface FilterBarProps {
  // this on click is to filter, but the data is being filtered automatically
  onClick: () => void;
  listOfTags: any[];
  selectedTags: string[];
  setSelectedTags: any;
  tagClicked: (tag: string) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  tagClicked,
  onClick,
  selectedTags,
  listOfTags,
}) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const handleInputChange = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setClicked(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  return (
    <div
      className="flex-col justify-center items-center relative"
      ref={filterRef}
    >
      <div className="relative w-284px shadow-tagShadow gap-2 z-40">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <img src={FilterIcon} alt="" className="ml-2" />
        </div>
        <div
          onClick={handleInputChange}
          className="block w-full py-2 pl-12 pr-4 text-base placeholder:text-base font-normal placeholder:font-normal text-black placeholder:text-black border border-Grey rounded-40px bg-white outline-none font-FiraSans placeholder:font-FiraSans"
        >
          Filtra
        </div>
      </div>
      {clicked && (
        <div
          className="absolute w-262px pt-8 px-4 pb-4 justify-center items-center content-center gap-2 flex-wrap rounded-b-lg bg-greyLight z-20"
          style={{ top: "80%", left: "50%", transform: "translateX(-50%)" }}
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="flex w-229px items-start content-center gap-4 flex-wrap">
              {/* this is where the tags will be */}
              {listOfTags.map((tag: any) => (
                <Tag
                  key={tag.id}
                  label={tag.name}
                  isSlected={selectedTags.includes(tag.name)}
                  onClick={tagClicked}
                />
              ))}
            </div>
            <PrimaryButton
              buttonText={"FILTRA"}
              type={"button"}
              disabled={selectedTags.length === 0}
              onClick={onClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBar;
