import React from "react";
import { twMerge } from "tailwind-merge";

interface ProfileButtonProps {
  buttonText: string;
  type: string;
  disabled?: boolean;
  onClick?: () => void;
}

const PrimaryButton: React.FC<ProfileButtonProps> = ({
  buttonText,
  type,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type={type as "button" | "submit" | "reset"}
      onClick={onClick}
      className={twMerge(
        "flex py-1 px-4 justify-center items-center gap-10px text-white text-center font-FiraSans text-xs font-normal uppercase bg-RedPantone rounded-30px hover:underline",
        disabled ? "bg-opacity-50" : ""
      )}
    >
      {buttonText}
    </button>
  );
};

export default PrimaryButton;
