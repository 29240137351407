import React from "react";
import { twMerge } from "tailwind-merge";

interface ProfileButtonProps {
  title: string;
  icon: string;
  width?: string;
  height?: string;
  type: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SecondaryButton: React.FC<ProfileButtonProps> = ({
  title,
  height,
  width,
  type,
  disabled,
  onClick,
  icon,
}) => {
  return (
    <button
      disabled={disabled}
      type={type as "button" | "submit" | "reset"}
      onClick={onClick}
      className={twMerge(
        "flex border border-black py-1 px-4 justify-center items-center gap-1 text-black text-center font-FiraSans text-xs font-normal uppercase bg-white rounded-30px hover:underline",
        disabled ? "bg-opacity-50" : ""
      )}
      style={{ width: ` ${width} !important`, height: `${height} !important` }}
    >
      {title}
      {icon && <img src={icon} alt="" className="w-18px h-18px" />}
    </button>
  );
};

export default SecondaryButton;
