import React from "react";
import BackgroundSvg from "../../assets/svg/signUpBackground.svg";

export default function BaseBackground({children}:{children:React.ReactElement}) {
  return (
    <div
      className="w-full h-full flex items-center justify-center"
      style={{
        backgroundImage: `url(${BackgroundSvg})`,
        backgroundSize: "cover",
      }}
    >
        {children}
    </div>
  );
}
