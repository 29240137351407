import React from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { RootState } from "../../redux/store";
import Close from "../../assets/svg/Close_LG.svg";

interface TagProps {
  label: string;
  isSlected: boolean;
  onClick: (tagName: string) => void;
  deletable?: boolean;
}

const Tag: React.FC<TagProps> = ({ label, isSlected, onClick, deletable }) => {
  const secondaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.secondaryColor
  );

  const handleClick = (_event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(label);
    }
  };
  return (
    <div className="flex flex-row space-x-">
      <div
        className={twMerge(
          "flex whitespace-nowrap cursor-pointer py-1 px-3 justify-center items-center gap-10px rounded-40px border-spacing-0.5px  shadow-tagShadow text-xs"
        )}
        style={{
          background: isSlected ? secondaryColor : "white",
          color: isSlected ? "white" : "black",
          border: isSlected ? `1px solid ${secondaryColor}` : `1px solid grey`,
        }}
        onClick={handleClick}
      >
        {label} {deletable && <img alt="" src={Close} className="w-4 h-4" />}
      </div>
    </div>
  );
};

export default Tag;
