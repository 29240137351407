import { useEffect, useRef, useState } from "react";
import AddIcon from "../../assets/svg/AddIcon.svg";
import CloseIcon from "../../assets/svg/CloseIcon.svg";
import PrimaryButton from "../primaryButton";
import Position from "../../assets/svg/PositionLIcon.svg";
import SaveIcon from "../../assets/svg/SaveIconM.svg";
import Tag from "../Tag";
import { InitialData } from "../../models/initialData";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { createPlaceAPI, getTagsAPI } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useOnMobile from "../../hook/useOnMobile";

interface MobileAddPhotoCardProps {
  setToastStatus: React.Dispatch<React.SetStateAction<string>>;

  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
}

const MobileAddPhotoCard: React.FC<MobileAddPhotoCardProps> = ({
  setToastStatus,
  setToastMessage,
}) => {
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const maxLength = 200;
  const [tagsList, setTagsList] = useState([]);
  const secondaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.secondaryColor
  );
  const isMobile = useOnMobile();

  const handlePlaceAdditionResult = (value: string, message: string) => {
    setToastStatus(value);
    setToastMessage(message);
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Image è obbligatorio"),
    title: Yup.string().required("titolo è obbligatorio"),
    description: Yup.string().required("Il descrizione è obbligatorio"),
    location: Yup.string().required("posto è obbligatorio"),
    tags: Yup.array().min(1, "At least one tag is required"),
  });

  const initialData: InitialData = {
    image: "",
    title: "",
    description: "",
    location: "",
    tags: [],
  };

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    getTagsAPI
      .getTags()
      .then((res) => {
        setTagsList(res.data.tags);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutateAsync: createPlaceMutation } = useMutation({
    mutationFn: createPlaceAPI.createPlace,
    onSuccess: () => {
      queryClient.invalidateQueries("places");
      // add logic to show
      handlePlaceAdditionResult(
        "success",
        "Il contenuto è stato caricato con successo!"
      );
    },
    onError: () => {
      handlePlaceAdditionResult(
        "error",
        "Ops! Qualcosa è andato storto, riprova più tardi."
      );
    },
  });

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values: InitialData, { resetForm }) => {
        try {
          await createPlaceMutation(values);
          setModalIsOpen(false);
          resetForm();
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => {
        // handleFormChange(formik.values);
        const openCamera = () => {
          fileInputRef.current?.click();
        };
        const handleFileChange = (event: any) => {
          const file = event.target.files?.[0];
          if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
              // Set the image in the state
              formik.setFieldValue("image", reader.result);
            };

            reader.readAsDataURL(file);
          }
          setModalIsOpen(true);
        };
        const tagClick = (tagObject: any) => {
          if (formik.values.tags.includes(tagObject)) {
            formik.setFieldValue(
              "tags",
              formik.values.tags.filter((tag) => tag !== tagObject)
            );
          } else {
            formik.setFieldValue("tags", [...formik.values.tags, tagObject]);
          }
        };
        const isTagSelected = (currentTag: any): boolean => {
          return formik.values.tags.includes(currentTag);
        };

        return (
          <div className="top-1/2 fixed right-0">
            <Form>
              <div
                className="top-1/2 right-0 justify-center ml-4 mb-4 w-[50px] h-[50px] p-10px flex-col items-center gap-2 rounded-l-18px border border-white  shadow-tagShadow"
                style={{
                  background: secondaryColor,
                  position: "fixed",
                  top: "50%",
                  right: 0,
                }}
                onClick={openCamera}
              >
                <img className="" src={AddIcon} alt="" />
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  // name={`image`}
                  // capture="user"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              {modalIsOpen && (
                <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="flex flex-col items-center bg-white w-11/12 h-3/4 rounded-18px">
                    {/* modal top */}
                    <div className="flex w-full pt-4 pr-4 pb-4 pl-10 justify-end items-start gap-5px  bg-lightBlack rounded-t-18px">
                      <div className="flex justify-between items-center flex-1">
                        <p className="text-white text-base font-normal">
                          Publica un nuovo contenuto
                        </p>
                        <img
                          src={CloseIcon}
                          alt="close icon"
                          className="w-3 h-3"
                          onClick={() => {
                            setModalIsOpen(false);
                          }}
                        />
                      </div>
                    </div>

                    {/* body */}
                    <div className="flex flex-col w-full h-full py-2 px-2 items-start gap-2 bg-white border overflow-y-auto rounded-b-18px ">
                      {/* title */}
                      <input
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Aggiungi un titolo..."
                        className="flex p-1 bg-greyLight w-full items-center font-FiraSans placeholder:font-FiraSans text-black placeholder:text-black placeholder:font-normal font-normal placeholder:text-sm text-sm outline-none rounded-sm"
                      />
                      {formik.errors.title && formik.touched.title && (
                        <p className="flex text-xs ml-1 text-RedPantone font-FiraSans font-semibold">
                          {formik.errors.title.toString()}
                        </p>
                      )}
                      {/* image */}
                      <div className="flex items-center w-full h-[300px]">
                        <img
                          className="flex w-full h-full items-center object-cover gap-2 p-1 flex-col rounded-18px"
                          // src={formik.values.image}
                          src={formik.values.image}
                          alt="file choosen"
                        />
                      </div>
                      {/* text area */}
                      <div className="flex flex-col w-full">
                        <textarea
                          className="flex w-full h-[200px] p-10px items-start gap-10px rounded-lg border border-Grey bg-greyLight outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-sm text-sm placeholder:font-normal font-normal"
                          placeholder="Aggiungi una descrizione..."
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={formik.values.description.length > 200}
                        ></textarea>
                        <p className="text-xs font-normal text-black text-right font-FiraSans">
                          {formik.values.description.length}/{maxLength}{" "}
                          characters
                        </p>
                        {formik.errors.description &&
                          formik.touched.description && (
                            <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                              {formik.errors.description.toString()}
                            </p>
                          )}
                      </div>

                      {/* location */}
                      <div className="flex flex-col w-full">
                        <div className="relative w-full">
                          <input
                            type="text"
                            id="input-group-1"
                            className="bg-white border border-Grey rounded-lg w-full p-2 outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-sm text-sm placeholder:font-normal font-normal"
                            placeholder="Aggiungi un luogo"
                            name="location"
                            value={formik.values.location}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div className="absolute inset-y-0 end-0 p-4 flex items-center ps-3.5 pointer-events-none">
                            <img
                              src={Position}
                              alt=""
                              className="w-18px h-18px"
                            />
                          </div>
                        </div>
                        {formik.errors.location && formik.touched.location && (
                          <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                            {formik.errors.location.toString()}
                          </p>
                        )}
                      </div>

                      {/* tags */}
                      <div className="flex flex-col items-center w-full min-h-250px bg-greyLight rounded-lg">
                        <div className="flex flex-col w-full">
                          <div className="relative w-full">
                            <input
                              type="text"
                              id="input-group-2"
                              disabled={true}
                              className="bg-white border border-Grey rounded-lg w-full p-2 outline-none placeholder:text-black text-black placeholder:font-FiraSans font-FiraSans placeholder:text-sm text-sm placeholder:font-normal font-normal"
                              placeholder="Aggiungi un tag"
                            />
                            <div className="absolute inset-y-0 end-0 p-4 flex items-center ps-3.5 pointer-events-none">
                              <img
                                src={SaveIcon}
                                alt=""
                                className="w-18px h-18px"
                              />
                            </div>
                          </div>
                          {formik.errors.tags && (
                            <p className="flex text-xs py-1 ml-3 text-RedPantone font-FiraSans font-semibold">
                              {formik.errors.tags.toString()}
                            </p>
                          )}
                        </div>
                        <div className="grid grid-cols-3 w-full pt-4 px-2 pb-4 gap-2">
                          {tagsList?.map((tag: any, index) => (
                            <Tag
                              key={index}
                              label={tag.name}
                              isSlected={isTagSelected(tag)}
                              onClick={() => tagClick(tag)}
                            />
                          ))}
                        </div>
                      </div>
                      {/* submit button */}
                      <div className="flex items-center justify-center w-full">
                        <PrimaryButton
                          type={"submit"}
                          buttonText={"PUBBLICA"}
                          disabled={!formik.isValid || formik.isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
export default MobileAddPhotoCard;
